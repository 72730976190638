// Libraries
import stringify from 'json-stringify-safe';

// Utils
import { currentLanguage } from 'i18n';
import { jsonaDeserialize } from 'common/utils/helpers';

// Errors
import { logError } from 'common/utils/errors';

const API_BASE_URL = '/api/v1';

function generateHeaders() {
  return {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'Content-Type': 'application/vnd.api+json',
  };
}

export function createQueryString(params = {}) {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length) {
        value.map((v) => queryParams.append(`${key}[]`, v));
      } else {
        queryParams.append(`${key}[]`, value);
      }
    } else {
      queryParams.append(encodeURIComponent(key), encodeURIComponent(value));
    }
  });

  return queryParams.toString();
}

// eslint-disable-next-line default-param-last
export async function apiCall(url, method = 'GET', body, retries = 0) {
  try {
    const response = await fetch(API_BASE_URL + url, {
      headers: generateHeaders(),
      method,
      body,
    });

    if (!response.ok) {
      const error = new Error(response.statusText);
      try {
        error.details = await response.json();
      } catch {
        throw error;
      }
      throw error;
    }

    return response.json();
  } catch (e) {
    logError(e);
    if (retries < 3) {
      const backoffDelay = 100 * 2 ** retries;
      await new Promise((resolve) => {
        setTimeout(resolve, backoffDelay);
      });
      console.warn(`Retrying request in ${backoffDelay}ms to ${url} after error: ${e}`);
      return apiCall(url, method, body, retries + 1);
    }
    throw e;
  }
}

export function createResponseBody(question, submission, response) {
  return JSON.stringify({
    data: {
      type: 'response',
      attributes: {
        answer: stringify(response),
        locale: currentLanguage,
      },
      relationships: {
        submission: {
          data: { type: 'submission', id: submission.id, guid: submission.guid },
        },
        question: {
          data: { type: 'question', id: question.id },
        },
      },
    },
  });
}

export async function fetcher(requestFn, ...args) {
  const response = await requestFn(...args);
  if (response.data) return jsonaDeserialize(response);
  return response;
}
